// call button colors
$shadow-black: rgba(0, 0, 0, 0.15);
$box-black: #3a3a3a;
$light-purple: #b39ae9;
$light-blue: #63d2d6;
$darker-blue: #3b85f4;

@mixin bg-option {
  background-repeat: repeat;
  background-position: 0 0;
}

.sales-simplify-messages-list {
  list-style: none;
  margin: 0;
  padding: 20px;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  li {
    margin-bottom: 10px;
    position: relative;

    &.from-customer {
      text-align: right;

      .sales-simplify-tooltip::before {
        right: 0;
      }
    }

    .date {
      color: $text-color;
      font-size: 11px;
      margin-left: 50px;
    }
  }
}

.sales-simplify-messages-background {
  background-color: $bg-color;
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow: scroll;
  overflow-x: hidden;

  &.bg-1 {
    @include bg-option;
    background-image: url('https://s3.amazonaws.com/sales-simplify/bg-1.png');
  }

  &.bg-2 {
    @include bg-option;
    background-image: url('https://s3.amazonaws.com/sales-simplify/bg-2.png');
  }

  &.bg-3 {
    @include bg-option;
    background-image: url('https://s3.amazonaws.com/sales-simplify/bg-3.png');
  }

  &.bg-4 {
    @include bg-option;
    background-image: url('https://s3.amazonaws.com/sales-simplify/bg-4.png');
  }
}

.sales-simplify-spacial-message {
  color: $text-color;
  margin-bottom: 15px;

  &.right {
    line-height: normal;
    text-align: right;
    color: $text-color-light;
    margin-bottom: 15px;
  }

  &.away {
    margin-bottom: 0;
    margin-top: 15px;
  }

  &.ml50 {
    margin-left: 50px;
  }

  &.with-background {
    background-color: $grey;
    border-radius: 10px;
    box-shadow: $message-shadow;
    padding: 20px;
  }

  &.auth {
    background: $white;

    .accquire-information {
      padding: 0;
    }
  }

  ~ .sales-simplify-spacial-message {
    margin-top: 15px;
  }
}

.sales-simplify-message {
  background-color: $grey;
  border-radius: $core-spacing;
  border-bottom-left-radius: 2px;
  box-shadow: $message-shadow;
  color: $text-color;
  display: inline-block;
  margin: 0 $core-spacing 5px 50px;
  padding: 12px $core-spacing;
  position: relative;
  text-align: left;
  word-break: break-word;

  &.top {
    border-top-left-radius: 2px;
    border-bottom-left-radius: $core-spacing;
  }

  &.from-customer {
    background-color: $main-color;
    border-bottom-left-radius: $core-spacing;
    border-bottom-right-radius: 2px;
    color: $white;
    margin: 0 0 5px $core-spacing;
    text-align: right;

    a {
      color: $white;
    }

    &.gray {
      background-color: $grey;
      color: $text-color;
      margin-bottom: 0;
    }
  }

  &.attachment {
    background: $white !important;
    border: 0;

    > span {
      display: none;
    }
  }

  p {
    margin-top: 0;
    word-break: break-word;
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  img {
    max-width: 240px;
  }

  ul {
    list-style-type: disc;
    padding-left: $core-spacing;

    li {
      margin-bottom: 5px;
      overflow: visible;
    }
  }

  ol {
    list-style-type: decimal;
    padding-left: $core-spacing;

    li {
      margin-bottom: 5px;
      overflow: visible;
    }
  }

  h3 {
    font-size: 16px;
    margin-bottom: 8px;
    margin-top: 0;
  }

  blockquote {
    border-left: 5px solid $border-color;
    font-size: 16px;
    margin: 0;
    padding: $core-spacing / 2 $core-spacing;
  }

  pre {
    background-color: $grey;
    border: 1px solid $border-color;
    border-radius: $border-radius;
    color: lighten($black, 30%);
    display: block;
    font-size: 13px;
    line-height: 1.42857143;
    margin: 0;
    padding: 9.5px;
    word-break: break-all;
    word-wrap: break-word;
  }

  a {
    color: $main-color;
    outline: 0;
  }
}

.file-wrapper {
  color: $text-color;
  overflow: hidden;
  padding-left: $core-spacing * 1.5;
  position: relative;

  svg {
    height: $core-spacing;
    left: 0;
    position: absolute;
    top: 0;
    width: 16px;
  }

  span {
    direction: rtl;
    display: block;
    max-width: 150px;
    overflow: hidden;
    text-align: left;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &:hover {
    color: darken($text-color, 10%);
  }
}

.download-attachment {
  display: block;
  text-decoration: none;

  img {
    @include prefix(
      (
        transition: opacity 0.3s ease-in-out
      ),
      webkit moz ms o
    );

    &:hover {
      opacity: 0.8;
    }
  }
}

.sales-simplify-avatar {
  border-radius: 50%;
  bottom: 20px;
  height: 40px;
  left: 0;
  overflow: hidden;
  position: absolute;
  width: 40px;

  img {
    height: 100%;
    width: 100%;
  }

  &.top {
    top: 0;
  }
}

.btn-resend {
  background: $white;
  border: 0;
  border-radius: $border-radius;
  color: $text-color;
  cursor: pointer;
  margin-top: 5px;
  outline: 0;
  padding: 5px 15px;

  &:focus,
  &:hover {
    background: lighten($grey, 10%);
  }
}

.app-message-box {
  background: $white;
  border-radius: 10px;
  border-top: 3px solid $darker-blue;
  box-shadow: 0 0 10px 2px $shadow-black;
  color: $box-black;
  margin-bottom: 8px;
  padding: 0;

  &.call-request {
    margin-bottom: 20px;
    padding: 16px;

    h5 {
      margin: 0 0 5px 0;
      font-size: 15px;
      font-weight: 700;
    }

    .call-buttons {
      display: flex;

      button {
        flex: 1;
        padding: 8px 16px;
        font-size: 13px;
        display: flex;
        align-items: center;
        justify-content: center;

        &:last-child {
          margin-left: 10px;
        }

        &:hover {
          opacity: 0.9;
        }

        span {
          margin-left: 5px;
        }

        svg {
          fill: #fff;
        }
      }
    }

    p {
      margin: 0 0 15px;
    }
  }

  &.spaced {
    margin-left: 50px;
  }

  &.flexible {
    display: inline-block;
  }

  .user-info {
    align-items: center;
    display: flex;
    flex-direction: column;
    padding: $core-spacing;
    text-align: center;

    h2 {
      font-size: 18px;
      margin: $core-spacing 0 0;

      > span {
        font-size: 24px;
      }
    }

    h4 {
      font-size: 14px;
      margin: 0 0 -10px;
      font-weight: 500;
      text-align: center;
    }

    img {
      background: $light-blue;
      border: 1px solid $grey-light;
      border-radius: 60px;
      font-size: 20px;
      height: 60px;
      line-height: 60px;
      overflow: hidden;
      width: 60px;
    }

    strong {
      font-weight: 500;
    }

    &.horizontal {
      flex-direction: row;

      strong {
        margin-left: 5px;
      }
    }
  }

  .call-button {
    border-top: 1px solid $border-color;
    padding: $core-spacing;
    text-align: center;

    h3 {
      margin-bottom: 15px;
      margin-top: 0;
      text-align: center;
    }

    button,
    > a {
      background: $darker-blue;
      border: 1px solid transparent;
      border-color: $darker-blue;
      border-radius: 5px;
      box-shadow: none;
      color: $white;
      cursor: pointer;
      font-size: 14px;
      padding: 10px 20px;
      text-transform: initial;
      transition: all 0.3s ease;
      width: 100%;

      &:hover {
        box-shadow: 0 2px 10px 0 $darker-blue;
      }

      &:focus {
        outline: 0;
      }
    }

    > a {
      text-decoration: none;
      display: block;
      width: auto;
      text-align: center;
    }

    .join-call {
      margin-top: 10px;
      font-size: 12px;
      text-transform: lowercase;
      color: $text-color-light;
    }
  }
}
