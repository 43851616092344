// Colors
$main-color: #673fbd;
$primary-color: #6569DF;
$grey-light: #eee;
$border-color: #ddd;
$text-color: #686868;
$text-color-light: #888;

$white: #fff;
$black: #000;
$red: #ea475d;
$grey: #eaebed;
$green: #3ccc38;
$link-blue: #007bff;

$transparent-white: rgba(255, 255, 255, .3);
$scroll-color: rgba(215, 215, 215, .7);

// Backgrounds
$bg-color: #faf9fb;

// Dimensions
$core-spacing: 20px;
$border-radius: 10px;
$break-small: 420px;
$break-medium: 500px;
$kb-spacing: 20px;
$font-size: 14px;

// shadows
$message-shadow: 0 1px 1px 0 rgba($black, .2);
$base-shadow: 0 7px 25px 5px rgba($black, .1);

// z index
$z-index-above: 2147483647;

// timing
$timing-slide: cubic-bezier(.35, .35, .35, .98);
$timing-scale: cubic-bezier(.25, .46, .45, .94);

$widget-logo: 'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNiIgZmlsbD0iY3VycmVudENvbG9yIiBjbGFzcz0iYmkgYmktY2hhdC1sZWZ0LWRvdHMiIHZpZXdCb3g9IjAgMCAxNiAxNiI+CiAgPHBhdGggZD0iTTE0IDFhMSAxIDAgMCAxIDEgMXY4YTEgMSAwIDAgMS0xIDFINC40MTRBMiAyIDAgMCAwIDMgMTEuNTg2bC0yIDJWMmExIDEgMCAwIDEgMS0xaDEyek0yIDBhMiAyIDAgMCAwLTIgMnYxMi43OTNhLjUuNSAwIDAgMCAuODU0LjM1M2wyLjg1My0yLjg1M0ExIDEgMCAwIDEgNC40MTQgMTJIMTRhMiAyIDAgMCAwIDItMlYyYTIgMiAwIDAgMC0yLTJIMnoiLz4KICA8cGF0aCBkPSJNNSA2YTEgMSAwIDEgMS0yIDAgMSAxIDAgMCAxIDIgMHptNCAwYTEgMSAwIDEgMS0yIDAgMSAxIDAgMCAxIDIgMHptNCAwYTEgMSAwIDEgMS0yIDAgMSAxIDAgMCAxIDIgMHoiLz4KPC9zdmc+';

$close: 'data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="22" height="24" viewBox="0 0 24 24" fill="none" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><line x1="18" y1="6" x2="6" y2="18" /><line x1="6" y1="6" x2="18" y2="18" /></svg>';