.sales-simplify-form {
  font-size: $font-size;
  margin: 0;
  overflow: hidden;

  .sales-simplify-form-content {
    list-style: none;
    margin: 0;
    padding: 0 20px 20px;
    position: relative;

    .sales-simplify-description {
      color: lighten($black, 36%);
      margin-bottom: 10px;
    }
  }

  .sales-simplify-result {
    color: $text-color;

    p {
      margin: 0;
    }
  }

  .sales-simplify-button {
    box-shadow: 0 5px 15px 3px rgba($black, .1);
    margin-top: 15px;

    &:hover,
    &:focus {
      box-shadow: 0 5px 20px 5px rgba($black, .1);
    }
  }

  .react-datepicker__input-container {
    display: block;
  }
}

// datepicker

.react-datepicker-wrapper {
  display: block;
}

.react-datepicker {
  border-color: $border-color !important;
  box-shadow: 0 5px 15px -3px rgba($black, .15);

  .react-datepicker__day--selected,
  .react-datepicker__day--keyboard-selected {
    background-color: $main-color;
    border-radius: $border-radius / 2;

    &:hover {
      background-color: darken($main-color, 10%);
    }
  }

  .react-datepicker__header {
    background: $bg-color;
    border-color: $border-color;

    .react-datepicker__current-month, 
    .react-datepicker-time__header {
      font-weight: 500;
    }
  }

  .react-datepicker__triangle {
    border-bottom-color: $bg-color !important;

    &::before {
      border-bottom-color: $border-color !important;
    }
  }
}
