.bot-message {
	margin: 0 20px 5px 50px;
	max-width: 100%;
	
  img {
		max-width: 100%;
		display: inherit;
	}

	.image-message {
		border-radius: 8px;
		box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.2);
	}
} 

.cards-wrapper {
	position: relative;

	.cards-navigation {
		position: absolute;
		width: 100%;
    height: 100%;
    left: 0;
    right: 0;
		display: flex;
		align-items: center;
		pointer-events: none;

		.arrow {
			margin-bottom: 60px;
			width: 36px;
			height: 36px;
			display: flex;
			border-radius: 100%;
			box-shadow: 0 0 15px 0 rgba(0,0,0,.35);
			align-items: center;
			justify-content: center;
			transition: all .3s;
			cursor: pointer;
			background: $white;
			pointer-events: all;
			z-index: 2;
			
			&.next {
				margin-left: auto;
				margin-right: 8px;
			}

			&.prev {
				margin-left: -18px;
			}
		}
	}
}

.cards {
	display: flex;
	align-items: flex-start;
	transition: transform .3s;
}

.card-item {
	flex-shrink: 0;
	margin-right: 15px;
	width: 240px;
	background: $white;
	border-radius: 8px;
	border: 1px solid rgb(219, 225, 232);
	overflow: hidden;
	opacity: 0.7;
	transition: opacity .3s;
	box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.1);
	user-select: none;

	&.active {
		opacity: 1;
	}

	.card-content {
		padding: 8px 16px 16px 16px; 

		p {
			color: $text-color;
		}
	}

	h4, p {
		margin-top: 8px;
		margin-bottom: 0;
	}

	.card-actions {
		margin-bottom: 1px;

		.card-action {
			padding: 8px 16px;
			border-top: 1px solid rgb(219, 225, 232);
			display: block;
			text-align: center;
			font-weight: 500;
	
			&:hover {
				background: $bg-color;
				cursor: pointer;
			}
		}
	}
}

.quick-replies {
	display: flex;
	flex-wrap: wrap;
	margin-left: -4px;

	.reply-button {
		padding: 5px 15px;
		border: 1px solid #673fbd;
		margin: 4px;
		border-radius: 20px;
		font-weight: 500;
		color: #333;

		&:hover {
			opacity: 0.8;
			cursor: pointer;
		}
	}
}

.bot-indicator {
	display: inline-block;
  margin-bottom: 1px;

  span {
    height: 6px;
    width: 6px;
    float: left;
    margin: 0 2px;
    background-color: #555;
    display: block;
    border-radius: 50%;
    animation-name: wave;
    animation-duration: 1.3s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
  }

  span:nth-of-type(1) {
    animation-delay: 0.2s;
  }

  span:nth-of-type(2) {
    animation-delay: 0.3s;
  }

  span:nth-of-type(3) {
    animation-delay: 0.4s;
  }
}

@keyframes wave {
  0%{
    transform:translateY(0px)
  }
  28%{
    transform:translateY(-5px)
  }
  44%{
    transform:translateY(0px)
  }
}