
//dot animation

.typing-wrapper{
    .dot-container {
        padding: 0px 12px;
        line-height: 30px;
        font-size: 13px; 
        width: 100px;

        .each-dot {
            display: inline-block;
            width: 8px;
            height: 8px;
            border-radius: 50%;
            margin-right: 3px;
            background: #303131;
            animation: typingAnimation 1.1s linear infinite;
        
            &:nth-child(2) {
                opacity: 0.8;
                animation-delay: -1.0s;
            }
        
            &:nth-child(3) {
                opacity: 0.6;
                animation-delay: -0.9s;
            }
        }
    }
    
    @keyframes typingAnimation {
        0%, 60%, 100% {
            transform: initial;
        }
    
        30% {
            transform: translateY(-15px);
        }
    }
}

.content-wrapper{
    p {
        font-size: 1.1rem;
    }
}
.react-tel-input .country-list{
    bottom: 30px;

}