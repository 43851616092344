[class*=" sales-simplify-icon-"],
[class^=sales-simplify-icon-] {
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  display: block;
  height: 32px;
  width: 32px;
}

.sales-simplify-icon-alarm {
  background-image: url('https://s3.amazonaws.com/sales-simplify/icons/alarm.svg');
}

.sales-simplify-icon-bar-chart {
  background-image: url('https://s3.amazonaws.com/sales-simplify/icons/bar-chart.svg');
}

.sales-simplify-icon-book {
  background-image: url('https://s3.amazonaws.com/sales-simplify/icons/book.svg');
}

.sales-simplify-icon-briefcase {
  background-image: url('https://s3.amazonaws.com/sales-simplify/icons/briefcase.svg');
}

.sales-simplify-icon-calculator {
  background-image: url('https://s3.amazonaws.com/sales-simplify/icons/calculator.svg');
}

.sales-simplify-icon-calendar {
  background-image: url('https://s3.amazonaws.com/sales-simplify/icons/calendar.svg');
}

.sales-simplify-icon-clipboard {
  background-image: url('https://s3.amazonaws.com/sales-simplify/icons/clipboard.svg');
}

.sales-simplify-icon-cloudcomputing {
  background-image: url('https://s3.amazonaws.com/sales-simplify/icons/cloudcomputing.svg');
}

.sales-simplify-icon-compass {
  background-image: url('https://s3.amazonaws.com/sales-simplify/icons/compass.svg');
}

.sales-simplify-icon-computer {
  background-image: url('https://s3.amazonaws.com/sales-simplify/icons/computer.svg');
}

.sales-simplify-icon-dashboard {
  background-image: url('https://s3.amazonaws.com/sales-simplify/icons/dashboard.svg');
}

.sales-simplify-icon-diagram {
  background-image: url('https://s3.amazonaws.com/sales-simplify/icons/diagram.svg');
}

.sales-simplify-icon-diamond {
  background-image: url('https://s3.amazonaws.com/sales-simplify/icons/diamond.svg');
}

.sales-simplify-icon-bar-earthgrid {
  background-image: url('https://s3.amazonaws.com/sales-simplify/icons/bar-earthgrid.svg');
}

.sales-simplify-icon-flag {
  background-image: url('https://s3.amazonaws.com/sales-simplify/icons/flag.svg');
}

.sales-simplify-icon-folder {
  background-image: url('https://s3.amazonaws.com/sales-simplify/icons/folder.svg');
}

.sales-simplify-icon-head-1 {
  background-image: url('https://s3.amazonaws.com/sales-simplify/icons/head-1.svg');
}

.sales-simplify-icon-home {
  background-image: url('https://s3.amazonaws.com/sales-simplify/icons/home.svg');
}

.sales-simplify-icon-idea {
  background-image: url('https://s3.amazonaws.com/sales-simplify/icons/idea.svg');
}

.sales-simplify-icon-laptop {
  background-image: url('https://s3.amazonaws.com/sales-simplify/icons/laptop.svg');
}

.sales-simplify-icon-like {
  background-image: url('https://s3.amazonaws.com/sales-simplify/icons/like.svg');
}

.sales-simplify-icon-medal {
  background-image: url('https://s3.amazonaws.com/sales-simplify/icons/medal.svg');
}

.sales-simplify-icon-megaphone {
  background-image: url('https://s3.amazonaws.com/sales-simplify/icons/megaphone.svg');
}

.sales-simplify-icon-paste {
  background-image: url('https://s3.amazonaws.com/sales-simplify/icons/paste.svg');
}

.sales-simplify-icon-piechart {
  background-image: url('https://s3.amazonaws.com/sales-simplify/icons/piechart.svg');
}

.sales-simplify-icon-piggybank {
  background-image: url('https://s3.amazonaws.com/sales-simplify/icons/piggybank.svg');
}

.sales-simplify-icon-puzzle {
  background-image: url('https://s3.amazonaws.com/sales-simplify/icons/puzzle.svg');
}

.sales-simplify-icon-scale {
  background-image: url('https://s3.amazonaws.com/sales-simplify/icons/scale.svg');
}

.sales-simplify-icon-settings {
  background-image: url('https://s3.amazonaws.com/sales-simplify/icons/settings.svg');
}

.sales-simplify-icon-star {
  background-image: url('https://s3.amazonaws.com/sales-simplify/icons/star.svg');
}

.sales-simplify-icon-tools {
  background-image: url('https://s3.amazonaws.com/sales-simplify/icons/tools.svg');
}

.sales-simplify-icon-umbrella {
  background-image: url('https://s3.amazonaws.com/sales-simplify/icons/umbrella.svg');
}

.sales-simplify-icon-paintpalette {
  background-image: url('https://s3.amazonaws.com/sales-simplify/icons/paintpalette.svg');
}

.sales-simplify-icon-users {
  background-image: url('https://s3.amazonaws.com/sales-simplify/icons/users.svg');
}

.sales-simplify-icon-stamp {
  background-image: url('https://s3.amazonaws.com/sales-simplify/icons/stamp.svg');
}

.sales-simplify-icon-phone-call {
  background-image: url('https://s3.amazonaws.com/sales-simplify/icons/phone-call.svg');
}

.sales-simplify-icon-magicwand {
  background-image: url('https://s3.amazonaws.com/sales-simplify/icons/magicwand.svg');
}

.sales-simplify-icon-folder {
  background-image: url('https://s3.amazonaws.com/sales-simplify/icons/folder.svg');
}



