.sales-simplify-message-sender {
  border-top: 1px solid lighten($grey-light, 1%);
  box-shadow: 0 0 15px 0 rgba($black, 0.1);
  font-size: 12px;
  min-height: 61px;
  overflow: auto;
  position: relative;
  max-height: 50%;
  border-bottom-right-radius: 25px;
  border-bottom-left-radius: 10px;

  textarea {
    @include prefix(
      (
        box-sizing: border-box !important
      ),
      webkit moz ms o
    );
    border: 0;
    color: $text-color;
    font-size: $font-size;
    height: 100%;
    height: calc(100% - 1px);
    left: 0;
    line-height: 20px;
    max-width: 100%;
    outline: none;
    padding: 20px 25px;
    padding-right: 80px;
    position: absolute;
    resize: none;
    top: 0;
    width: 100%;
    overflow: hidden;

    &:focus {
      @include prefix(
        (
          box-shadow: none !important
        ),
        webkit moz ms o
      );
    }
  }

  .ctrl {
    position: absolute;
    right: 15px;
    top: 13px;

    .ctrl-item {
      float: left;
      width: 35px;
      height: 35px;
      line-height: 35px;
      margin: 0;
      cursor: pointer;
      opacity: 0.7;
      text-align: center;
      font-size: 14px;

      &:hover {
        opacity: 1;
      }

      svg {
        height: 36px;
      }

      input[type='file'] {
        display: none;
      }
    }
  }

  .loader {
    margin: 0;
    right: 20px;
    top: 17px;
  }
}
